import { structureIdTypes } from 'dmpconnectjsapp-base/constants';

export const getStructureIdTypeFromId = (structureId) => {
  const firstDigit = structureId.charAt(0);

  const structureType = Object.values(structureIdTypes)
    .find(sit => sit.firstDigit === Number(firstDigit));

  return structureType.value || undefined;
};
export const isStructureIdValid = (type, id) => {
  if (!type || !id) return false;

  const structureType = structureIdTypes[type];
  const regexp = new RegExp(`^\\${structureType.type}{${structureType.length}}$`);

  return regexp.test(id);
};
export const getStructureIdErrorMessage = (type) => {
  if (!type) return '';

  const structureType = structureIdTypes[type];
  const charType = structureType.type === 'd' ? 'chiffres' : 'caractères';
  return `L'identifiant doit contenir ${structureType.length} ${charType}.`;
};

export const extractPracticeLocationFromState = (state, rpps, serialNumber) => {
  const {
    dmpconnectCPxConfiguration: {
      [rpps]: savedPracticeLocation,
    },
  } = state;

  // if we didn't save any practice location, OR the cpx serialNumber changed
  if (!savedPracticeLocation || (serialNumber && serialNumber !== savedPracticeLocation.cpxSerialNumber)) return undefined;

  const {
    practiceLocationSetting,
    customPracticeLocationStructureName,
    customPracticeLocationStructureIdType,
    customPracticeLocationStructureId,
    customPracticeLocationActivitySector,
    practiceLocationSettingDetails,
  } = savedPracticeLocation;

  let definedPracticeLocation;

  if (practiceLocationSetting !== null && Number(practiceLocationSetting) >= 0) {
    definedPracticeLocation = {
      cpsPracticeLocationIndice: Number(practiceLocationSetting),
    };
    if (practiceLocationSettingDetails) {
      definedPracticeLocation = {
        ...definedPracticeLocation,
        structureName: practiceLocationSettingDetails.s_practiceLocationName,
        structureIdType: getStructureIdTypeFromId(practiceLocationSettingDetails.s_practiceLocationStructureId),
        structureId: practiceLocationSettingDetails.s_practiceLocationStructureId.substring(1),
        activitySector: practiceLocationSettingDetails.s_practiceLocationActivity,
      };
    }
  } else {
    definedPracticeLocation = {
      cpsPracticeLocationIndice: -1,
      structureName: customPracticeLocationStructureName,
      structureIdType: customPracticeLocationStructureIdType,
      structureId: customPracticeLocationStructureId,
      activitySector: customPracticeLocationActivitySector,
    };
  }

  return definedPracticeLocation;
};

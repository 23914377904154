import * as yup from 'yup';
import '../../utils/formUtils';
import { dmpconnectActionConstants } from 'dmpconnectjsapp-base/constants';
import { isReady } from 'dmpconnectjsapp-base/helpers/common';

export const extractOTPParamsChannelValues = (optEmailValue, otpMobileValue) => {
  const { s_otpChannelValue: mobilePhone = '' } = otpMobileValue;
  const { s_otpChannelValue: mail = '' } = optEmailValue;

  return { mobilePhone, mail };
};


export const extractOTPParamsFromAdminData = (adminDataSection) => {
  if (!isReady(adminDataSection)) return false;
  const {
    PatientData: {
      ExtendedContactDetails: {
        ContactDetails: { s_mobilePhone, s_mail },
      },
    },
  } = adminDataSection;

  return { mobilePhone: s_mobilePhone, mail: s_mail };
};

export const formatOtpParams = (ins, values) => ({
  mobilePhone: values.mobilePhone ? values.mobilePhone.replace(/ /g, '') : '',
  mail: values.mail ? values.mail : '',
});

export const createOTPSchema = yup.object({
  mobilePhone: yup.string().DMPCMobile(),
  mobilePhoneConfirm: yup.string().test(
    'mobilePhoneConfirm',
    'les numéros de mobile sont différents',
    function (value) {
      const mobile = this.parent.mobilePhone;
      return (
        (mobile === undefined || mobile === '')
        || (value && (value === mobile || value.replace(/ /g, '') === mobile))
      );
    },
  ),
  mail: yup.string().DMPCMaxLength(64),
  mailConfirm: yup.string().when('mail', {
    is: mail => mail !== '' && mail !== undefined,
    then: yup.string().required('Les emails sont différents').oneOf([yup.ref('mail')],
      'Les emails sont différents'),
  }),
});

export const createOTPDefaultValues = {
  mobilePhone: '',
  mail: '',
  mailConfirm: '',
  mobilePhoneConfirm: '',
};

export const generatePDFParams = (action, params) => {
  let mobilePhone = '';
  let mail = '';
  let civilStatus = {};
  if (action === dmpconnectActionConstants.DMPC_REACTIVATE_DMP) {
    ({ s_mobilePhone: mobilePhone, s_mail: mail } = params);
    civilStatus = {
      i_gender: Number(params.gender || params.i_gender),
      s_name: params.name || params.s_name,
      s_given: params.givenName || params.s_given,
    };
  } else {
    ({
      DmpAdministrativeData: {
        PatientData: {
          ExtendedContactDetails: {
            ContactDetails: { s_mobilePhone: mobilePhone, s_mail: mail },
          },
          ExtendedCivilStatus: {
            CivilStatus: civilStatus,
          },
        },
      },
    } = params);
  }

  return { mobilePhone, mail, civilStatus };
};

const phoneRegexp = /^(\d{2}).*(\d{2}).*(\d{2}).*(\d{2}).*(\d{2})$/;
export const formatPhoneNumber = number => number.replace(phoneRegexp, '$1 $2 $3 $4 $5');

import { put, take, select } from 'redux-saga/effects';
import { apiSections, dmpconnectActionConstants } from 'dmpconnectjsapp-base/constants';
import {
  dmpCommandFailureContextualizedType,
  dmpCommandSuccessContextualizedType,
} from 'dmpconnectjsapp-base/actions';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { getAccessRightsProps } from 'dmpconnectjsapp-base/rules/accessRights';
import {
  formatCloseDMPParams, formatcreateAcknowledgementPdf,
  formatCreateDMPParams, formatgetPatientWebPdfParams,
  formatUpdateUserDmpAccessAuthorizationParams,
} from 'dmpconnectjsapp-base/actions/config/commandParamsFormatters';
import {
  getAction,
} from '../actions';
import {
  dmpconnectGeneratePDFActions,
  dmpconnectSetTreatingPhysicianActions,
  userAuthorizationAction,
} from '../constants';
import { generatePDFParams } from '../helpers/administration/otp';

export const handleManageDMPProcess = function* () {
  while (true) {
    const {
      action, ins, params, generateOtp,
    } = yield take(dmpconnectActionConstants.DMPC_MANAGE_DMP_PROCESS);
    let result = null;

    let section = '';
    if (action === dmpconnectActionConstants.DMPC_CREATE_DMP_AND_OTP) { // DMPCONNECT-JS V2
      yield put(getAction(
        commands.createDMPAndOtp,
        apiSections.CREATE_DMP_SECTION,
        { ...formatCreateDMPParams(ins, params), i_createOTP: 1 },
        {
          synchronous: true,
          contextParams: { params },
          subSection: ins,
        },
      ));
      section = apiSections.CREATE_DMP_SECTION;
    } else if (action === dmpconnectActionConstants.DMPC_EDIT_DMP) {
      yield put(getAction(
        commands.updateDmpAdministrativeData,
        apiSections.EDIT_DMP_SECTION,
        params,
        {
          synchronous: true,
          contextParams: { params },
          subSection: ins,
        },
      ));
      section = apiSections.EDIT_DMP_SECTION;
    } else if (action === dmpconnectActionConstants.DMPC_CLOSE_DMP) {
      yield put(getAction(
        commands.closeDMP,
        apiSections.CLOSE_DMP_SECTION,
        formatCloseDMPParams(ins, params),
        {
          synchronous: true,
          contextParams: { params },
          subSection: ins,
        },
      ));
      section = apiSections.CLOSE_DMP_SECTION;
    } else if (action === dmpconnectActionConstants.DMPC_REACTIVATE_DMP) {
      yield put(getAction(
        commands.reactivateDMP,
        apiSections.REACTIVATE_DMP_SECTION,
        params,
        {
          synchronous: true,
          contextParams: { params },
          subSection: ins,
        },
      ));
      section = apiSections.REACTIVATE_DMP_SECTION;
    }

    result = yield take(
      [
        dmpCommandSuccessContextualizedType(section),
        dmpCommandFailureContextualizedType(section),
      ],
    );

    // generer le fichier pdf requis
    if (dmpconnectGeneratePDFActions.includes(action)
      && result
      && result.type === dmpCommandSuccessContextualizedType(section)
    ) {
      const { mobilePhone, mail, civilStatus } = generatePDFParams(action, params);
      if (generateOtp === true && (mobilePhone || mail)) {
        yield put(getAction(
          commands.getPatientWebAccessPdf,
          apiSections.GET_PATIENT_WEB_PDF_SECTION,
          formatgetPatientWebPdfParams({ ins, mobilePhone, mail }),
          {
            contextParams: {
              s_otpPhone: mobilePhone,
              s_otpEmail: mail,
              i_returnAsFile: 0,
            },
            subSection: ins,
          },
        ));
      } else {
        yield put(getAction(
          commands.createAcknowledgementPdf,
          apiSections.GET_PATIENT_ACK_PDF_SECTION,
          formatcreateAcknowledgementPdf({ ins, CivilStatus: civilStatus }),
          {
            contextParams: {
              CivilStatus: civilStatus,
              i_returnAsFile: 0,
            },
            subSection: ins,
          },
        ));
      }
    }

    // ajout accès médecin traitant
    if (
      dmpconnectSetTreatingPhysicianActions.includes(action)
      && result
      && result.type === dmpCommandSuccessContextualizedType(section)
      && params.isTreatingPhysician
    ) {
      const { accessRights: { psId } } = yield select(getAccessRightsProps);
      const setTPAction = getAction(
        commands.updateUserDmpAccessAuthorization,
        apiSections.USER_DMP_ACCESS_AUTHORIZATION_SECTION,
        formatUpdateUserDmpAccessAuthorizationParams(
          ins,
          userAuthorizationAction.AddAuthorization,
          1,
        ),
        {
          subSection: `${ins}/${psId}`,
          contextParams: {
            ins,
            performer: psId,
            fromSetTreatingPhysician: true,
            fromManageDMPProcess: true,
          },
        },
      );
      yield put(setTPAction);
    }
  }
};

export default {
  getChoicesFiltered(codes, filteredCodes) {
    return codes.filter(element => !filteredCodes.includes(element.code));
  },
  getChoices(opCodes, opCodeType) {
    return opCodes[opCodeType];
  },
  translate(opCodes, code) {
    const foundCode = opCodes.find(c => c.code === code);
    return foundCode !== undefined ? foundCode.label : code;
  },
};

import React from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { connect } from 'react-redux';
import { apiSections } from 'dmpconnectjsapp-base/constants';
import { clearSection } from 'dmpconnectjsapp-base/actions';
import commands from 'dmpconnectjsapp-base/actions/config/commands';
import { isInsFormatValid } from 'dmpconnectjsapp-base/utils/insUtils';
import {
  getError, isLoading, isReady,
} from 'dmpconnectjsapp-base/helpers/common';
import { canUpload } from 'dmpconnectjsapp-base/rules/accessRights';
import { getConfigurationValue } from 'dmpconnectjsapp-base/helpers/accessors';
import { getErrorMessage } from '../../dmpconnect/errors';
import ButtonWithLoader from '../Common/Form/ButtonWithLoader';
import {
  getAction, getDirectAuthenticationDMPStatus, selectCertifiedIdentity, setBackPage, setFindPatientsIns,
} from '../../dmpconnect/actions';
import checkAccessRights from '../AccessRights/CheckAccessRights';
import {
  getDirectAuthenticationStatusFromSection,
  getSelectedPatientInfos,
} from '../../dmpconnect/helpers/directAuthenticationDMPStatus';
import Alert from '../Common/Message/Alert';
import SimpleTable from '../Common/Table/SimpleTable';
import { getFullName } from '../../dmpconnect/helpers/accessibleDMPList';
import DroppableNameCell from './DroppableNameCell';
import DMPDate from '../Common/Date/DMPDate';
import DMPStatusProvider from '../DMPStatus/DMPStatusProvider';
import DMPStatusActions from '../DMPStatus/DMPStatusActions';
import DMPStatusExtraActions from '../DMPStatus/DMPStatusExtraActions';
import { dmpStatuses, searchPanels } from '../../dmpconnect/constants';
import { backPagesTypes } from '../../dmpconnect/helpers/nav';
import { isMssActive } from '../../dmpconnect/helpers';

const insSize = 22;

const AccessByINS = ({
  dispatch,
  inscToNir,
  findPatientsIns,
  selectIns,
  selector,
  directAuthenticationDMPStatusSection,
  accessRights,
  debugUloadCPE,
  showUploadForm,
  noDashboard,
  mssActive,
}) => {
  React.useEffect(() => {
    dispatch(setBackPage(backPagesTypes.DASHBOARD, `/home/${searchPanels.ACCESS_BY_INS_PANEL}`));
    if (!isLoading(inscToNir)) {
      dispatch(clearSection(apiSections.INSC_TO_NIR));
    }
  }, []);

  const handleChange = (event) => {
    dispatch(setFindPatientsIns(event.target.value));
  };

  const selectINS = () => {
    dispatch(clearSection(apiSections.INSC_TO_NIR));
    dispatch(clearSection(apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION));
    if (findPatientsIns.length === 22) {
      dispatch(getAction(
        commands.getInsNirFromInsC,
        apiSections.INSC_TO_NIR,
        { s_insC: findPatientsIns },
      ));
    } else {
      dispatch(getDirectAuthenticationDMPStatus(findPatientsIns));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      selectINS();
      e.preventDefault();
    }
  };

  const selectDMP = (ins) => {
    selectIns(ins);
    if (!selector) dispatch(selectCertifiedIdentity(null));
  };


  let insNir = findPatientsIns;
  if (isReady(inscToNir)) {
    insNir = inscToNir.Ins.s_ins + inscToNir.Ins.s_insType;
  }


  const displayUpload = canUpload(accessRights) || debugUloadCPE;
  const columns = React.useMemo(() => {
    let tmpColumns = [
      {
        Header: 'Patient',
        id: 'patientName',
        accessor: (d) => {
          const { name, givenName, birthName } = getSelectedPatientInfos(d);
          return getFullName(name, givenName, birthName);
        },
        // eslint-disable-next-line react/prop-types
        Cell: ({ row, value }) => (
          <>
            {!selector ? (
              <>
                {displayUpload ? (
                  <DroppableNameCell
                    // eslint-disable-next-line react/prop-types
                    onDrop={acceptedFiles => showUploadForm(
                      acceptedFiles,
                      row.original.ExistingTestAnswer.AdminData.Ins.s_ins + row.original.ExistingTestAnswer.AdminData.Ins.s_insType,
                    )}
                    // eslint-disable-next-line react/prop-types
                    ins={row.original.ExistingTestAnswer.AdminData.Ins.s_ins + row.original.ExistingTestAnswer.AdminData.Ins.s_insType}
                    text={value}
                  />
                ) : (
                  <span className="typography-big-text-title">{value}</span>
                )}
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-link btn-sm typography-big-text-title"
                  // eslint-disable-next-line react/prop-types
                  onClick={() => selectDMP(row.original.ExistingTestAnswer.AdminData.Ins.s_ins + row.original.ExistingTestAnswer.AdminData.Ins.s_insType)}
                >
                  {value}
                </button>
              </>
            )}
          </>
        ),
      },
      {
        Header: 'Né(e) le',
        id: 's_birthday',
        accessor: 'ExistingTestAnswer.AdminData.s_birthday',
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          <DMPDate
            dmpDate={value}
            inputFormat="YYYYMMDD"
            outputFormat="DD/MM/YYYY"
            className="typography-default-text-title"
          />
        ),
      },
      {
        id: 'dmp-access',
        Header: 'Accès au dossier patient',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          // eslint-disable-next-line react/prop-types
          <DMPStatusProvider
            getOnMount
            certifiedIns={row.original.ExistingTestAnswer.AdminData.Ins.s_ins + row.original.ExistingTestAnswer.AdminData.Ins.s_insType}
          >
            <DMPStatusActions
              accessDMP={ins => selectDMP(ins)}
              aldActive={false}
            />
          </DMPStatusProvider>
        ),
      },
      {
        id: 'actions',
        Header: 'Actions',
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => (
          // eslint-disable-next-line react/prop-types
          <DMPStatusProvider
            certifiedIns={row.original.ExistingTestAnswer.AdminData.Ins.s_ins + row.original.ExistingTestAnswer.AdminData.Ins.s_insType}
            showError={false}
          >
            <DMPStatusExtraActions showUploadForm={ins => showUploadForm([], ins)} mssActive={mssActive} />
          </DMPStatusProvider>
        ),
      },
    ];

    // TODO : move this test inside DMPStatusExtraActions when more than one action to display in the cell
    if (!canUpload(accessRights)) {
      tmpColumns = tmpColumns.filter(c => !['actions'].includes(c.id));
    }

    if (selector) {
      tmpColumns = tmpColumns.filter(c => !['actions', 'dmp-access'].includes(c.id));
    }

    return tmpColumns;
  }, []);

  const directAuthenticationDMPStatus = getDirectAuthenticationStatusFromSection(
    accessRights,
    directAuthenticationDMPStatusSection,
    insNir,
  );
  const {
    ExistingTestAnswer: {
      i_dmpStatus,
    } = {},
  } = directAuthenticationDMPStatus;

  const loading = isLoading(directAuthenticationDMPStatus) || isLoading(inscToNir);
  const error = getError(inscToNir) || getError(directAuthenticationDMPStatus);

  return (
    <>
      <Form>
        <Form.Group controlId="insForm">
          <Form.Label>Recherche par l&apos;INS</Form.Label>
          <Row>
            <Col sm="3" className="pr-2">
              <Form.Control
                size="sm"
                placeholder="Identifiant national de santé"
                value={findPatientsIns || ''}
                maxLength={insSize}
                onChange={event => handleChange(event)}
                onKeyPress={event => handleKeyPress(event)}
                readOnly={noDashboard}
              />

              <Alert type="info" className={`mt-2 ${error ? 'mb-0' : ''}`}>
                Formats de l&apos;identifiant national de santé
                <span className="d-block">Ins-C : 22 chiffres</span>
                <span className="d-block">Ins-NIR : 15 chiffres + 1 lettre</span>
              </Alert>

              {error && <Alert type="danger" className="mt-2">{getErrorMessage(error)}</Alert>}
            </Col>
            {!noDashboard && (
              <Col className="pl-0">
                <ButtonWithLoader
                  size="sm"
                  loading={loading}
                  label="Recherche"
                  onClick={() => selectINS()}
                  disabled={!isInsFormatValid(findPatientsIns)}
                />
              </Col>
            )}
          </Row>
        </Form.Group>
      </Form>
      {isReady(directAuthenticationDMPStatus) && (
        <>
          {![dmpStatuses.DMPError, dmpStatuses.DMPNotFound].includes(i_dmpStatus) ? (
            <SimpleTable columns={columns} data={[directAuthenticationDMPStatus]} striped />
          ) : (
            <Alert type="danger">
              Aucun DMP trouvé.
            </Alert>
          )}
        </>
      )}
    </>
  );
};

AccessByINS.propTypes = {
  selectIns: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  directAuthenticationDMPStatusSection: PropTypes.object.isRequired,
  inscToNir: PropTypes.object,
  accessRights: PropTypes.object.isRequired,
  findPatientsIns: PropTypes.string,
  debugUloadCPE: PropTypes.bool,
  showUploadForm: PropTypes.func,
  selector: PropTypes.bool,
  noDashboard: PropTypes.bool,
  mssActive: PropTypes.bool,
};
AccessByINS.defaultProps = {
  inscToNir: {},
  findPatientsIns: '',
  debugUloadCPE: false,
  showUploadForm: null,
  selector: false,
  noDashboard: false,
  mssActive: false,
};

function mapStateToProps(state) {
  const {
    dmpconnect: {
      [apiSections.DIRECT_AUTHENTICATION_DMP_STATUS_SECTION]: directAuthenticationDMPStatusSection,
      [apiSections.INSC_TO_NIR]: inscToNir,
    },
    dmpconnectUser: { findPatientsIns },
    dmpconnectConnectorConfig,
    dmpConnectPersistedAppConfiguration,
  } = state;
  return {
    debugUloadCPE: getConfigurationValue('debugUploadCPE', dmpconnectConnectorConfig),
    directAuthenticationDMPStatusSection,
    inscToNir,
    findPatientsIns,
    noDashboard: getConfigurationValue('noDashboard', dmpConnectPersistedAppConfiguration),
    mssActive: isMssActive(state),
  };
}

const connectedAccessByINS = connect(mapStateToProps)(AccessByINS);

export default checkAccessRights(connectedAccessByINS);
